<template>
  <div class="mb-6 mt-8">
    <div class="vx-col w-1/1.5">
      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Operating Unit</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <div class="vx-col w-1/2">
            <OperatingUnit
              :value="this.selectedoperatingUnit" @selected="(val) => { this.selectedoperatingUnit = val }"
              v-validate="'required'"></OperatingUnit>
            <span class="text-danger text-sm" v-show="errors.has('Operating Unit')">{{
                errors.first("Operating Unit")
              }}</span>
          </div>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Warehouse Code</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <div class="vx-col w-1/2">
            <Warehouse :value="this.selectedWarehouse" @selected="(val) => {
                    this.selectedWarehouse = val
                }" v-validate="'required'"></Warehouse>
            <span class="text-danger text-sm" v-show="errors.has('Warehouse')">{{ errors.first("Warehouse") }}</span>
          </div>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Counting Type</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <div class="vx-col w-1/2">
            <CountingType :value="this.selectedCountingType" @selected="(val) => {
                    this.selectedCountingType = val
              }" v-validate="'required'"
            ></CountingType>
            <span class="text-danger text-sm" v-show="errors.has('Counting Type')">{{ errors.first('Counting Type') }}</span>
          </div>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Counting Date</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <div class="vx-col w-1/2">
            <Date
              :default="false"
              @selected="(val) => { this.countingDate = val }"
              v-validate="'required'"
            ></Date>
            <span class="text-danger text-sm" v-show="errors.has('Counting Date')">{{
                errors.first("Counting Date")
              }}</span>
          </div>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Posting Date</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <div class="vx-col w-1/2">
            <Date
              :default="false"
              @selected="(val) => { this.postingDate = val }"
              v-validate="'required'"
            ></Date>
            <span class="text-danger text-sm" v-show="errors.has('Posting Date')">{{
                errors.first("Posting Date")
              }}</span>
          </div>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Cost Center</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <div class="vx-col w-1/2">
            <CostCenter
              :value="this.selectedCostCenter" @selected="(val) => { this.selectedCostCenter = val }"
              v-validate="'required'"></CostCenter>
            <span class="text-danger text-sm" v-show="errors.has('Cost Center')">{{
                errors.first("Cost Center")
              }}</span>
          </div>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>External Code</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <div class="vx-col w-1/2">
            <vs-input  class="w-full" v-model="external_code"></vs-input>
            <span class="text-danger text-sm" v-show="errors.has('External Code')">{{
                errors.first("External Code")
              }}</span>
          </div>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Note</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <div class="vx-col w-1/2">
            <vs-textarea v-model="note" class="w-full" v-validate="'required'"/>
            <span class="text-danger text-sm" v-show="errors.has('Note')">{{ errors.first("Note") }}</span>
          </div>
        </div>
      </div>

      <Attachment
        v-model="fileAttachment"
        v-validate="'required'"
      ></Attachment>
      <span class="text-danger text-sm" v-show="errors.has('fileAttachment')">{{
          errors.first("fileAttachment")
        }}</span>

      <ImportExcel
        v-model="importExcel"
        @input="handleFileInput"
        v-validate="'required'"
      ></ImportExcel>
      <span class="text-danger text-sm" v-show="errors.has('importExcel')">{{ errors.first("importExcel") }}</span>

      <div class="vx-row flex justify-between mt-5 m-10">
        <div>
          <vs-button class="mb-2" v-on:click="SubmitForm()">
            Upload
          </vs-button>
        </div>
        <div>
          <vs-button class="mb-2" v-on:click="handleClose()">Close</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OperatingUnit from "./OperatingUnit.vue";
import Warehouse from "./Warehouse.vue";
import Date from "./Date.vue";
import CostCenter from "./CostCenter.vue"
import Attachment from "./Attachment.vue"
import ImportExcel from "./ImportExcel.vue"
import CountingType from "./CountinType.vue"
import moment from "moment/moment";

export default {
  components: {
    OperatingUnit,
    Warehouse,
    Date,
    CostCenter,
    Attachment,
    ImportExcel,
    CountingType,
  },
  data() {
    return {
      selectedCountingType: null,
      countingDate: null,
      postingDate: null,
      selectedWarehouse: null,
      selectedoperatingUnit: null,
      selectedCostCenter: null,
      fileAttachment: [],
      importExcel: null,
      note: null,
      external_code: null,
      selectedToWarehouse: null,
    };
  },
  methods: {
    handleFileInput(file) {
      this.importExcel = file;
    },
    SubmitForm() {
      this.errors.clear();
      this.$validator.validateAll().then((validationPassed) => {
        let allValidationsPassed = true; // Flag to track validation status

        if (!this.selectedoperatingUnit) {
          this.errors.add({
            field: "Operating Unit",
            msg: "This field is required",
          });
          allValidationsPassed = false;
        }

        if (!this.selectedWarehouse) {
          this.errors.add({
            field: "Warehouse",
            msg: "This field is required",
          });
          allValidationsPassed = false;
        }

        if (!this.countingDate) {
          this.errors.add({
            field: "Counting Date",
            msg: "This field is required",
          });
          allValidationsPassed = false;
        }

        if (!this.postingDate) {
          this.errors.add({
            field: "Posting Date",
            msg: "This field is required",
          });
          allValidationsPassed = false;
        }

        if (!this.selectedCountingType) {
          this.errors.add({
            field: "countingType",
            msg: "This field is required",
          });
          allValidationsPassed = false;
        }

        if (this.countingDate && this.postingDate) {
          const postingDateFormatted = moment(this.postingDate).startOf('day');
          const countingDateFormatted = moment(this.countingDate).startOf('day');
          if (postingDateFormatted.isBefore(countingDateFormatted)) {
            this.errors.add({
              field: "Posting Date",
              msg: "Posting date cannot be less than counting date",
            });
            allValidationsPassed = false;
          }
        }

        if (!this.selectedCostCenter) {
          this.errors.add({
            field: "Cost Center",
            msg: "This field is required",
          });
          allValidationsPassed = false;
        }

        if (!this.external_code) {
          this.errors.add({
            field: "External Code",
            msg: "This field is required",
          });
          allValidationsPassed = false;
        }

        if (!this.note) {
          this.errors.add({
            field: "Note",
            msg: "This field is required",
          });
          allValidationsPassed = false;
        }

        if (this.fileAttachment.length === 0) {
          this.errors.add({
            field: "fileAttachment",
            msg: "This Attachment field is required",
          });
          allValidationsPassed = false;
        }

        if (!this.importExcel) {
          this.errors.add({
            field: "importExcel",
            msg: "This field is required",
          });
          allValidationsPassed = false;
        }

        if (allValidationsPassed) {
          this.postData();
        }
      });
    },
    postData() {
      let form = new FormData();
      form.append("operating_unit_code", this.selectedoperatingUnit.code);
      form.append("warehouse_code", this.selectedWarehouse.code);
      form.append("counting_type", this.selectedCountingType.label);
      form.append("counting_date", moment(this.countingDate).format("YYYY-MM-DD"));
      form.append("posting_date", moment(this.postingDate).format("YYYY-MM-DD"));
      form.append("cost_center_code", this.selectedCostCenter ? this.selectedCostCenter.code : "");
      form.append("note", this.note);
      form.append("external_code", this.external_code);

      for (let index = 0; index < this.fileAttachment.length; index++) {
        form.append("attachments[]", this.fileAttachment[index].File);
      }
      form.append("excel", this.importExcel);

      this.$vs.loading();
      this.$http
        .post("/api/wms/v1/stock-opname-form", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          if (resp.code === 200) {
            this.$vs.notify({
              title: "Success",
              text: "import running on background please wait a moment",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.handleClose();
          } else {
            this.$vs.notify({
              title: "Error",
              text: resp.message,
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    handleClose() {
      this.$vs.loading();
      this.$router.push({name: "stock-opname-form"});
      this.$vs.loading.close();
    },
  }
}
</script>
